import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";

const PinEntryPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const clubId = searchParams.get("clubId");
  const doorId = searchParams.get("doorId");

  const [pins, setPins] = useState(["", "", "", ""]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [clubName, setClubName] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const fetchClubName = async () => {
      try {
        const response = await axios.get(`/api/club-name?clubId=${clubId}`);
        setClubName(response.data);
      } catch (error) {
        console.error("Error fetching club name:", error);
        setError("Club not found.");
      }
    };

    if (clubId) {
      fetchClubName();
    }
  }, [clubId]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    const newPins = [...pins];
    newPins[index] = value;
    setPins(newPins);
    setError("");
    setSuccess("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setSuccess("");

    const pin = pins.join("");

    if (pin.length !== 4 || !/^\d+$/.test(pin)) {
      setError("Please enter a valid 4-digit PIN.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `/api/doors/pin-validate?clubId=${clubId}&doorId=${doorId}`,
        { pin }
      );

      if (response.data.success) {
        setSuccess("PIN verified successfully!");
        setPins(["", "", "", ""]);
      } else {
        setError("Invalid PIN. Please try again.");
        setPins(["", "", "", ""]);
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        if (error.response.status === 400) {
          setError(
            "PIN validation failed. Please check your PIN and try again."
          );
          setPins(["", "", "", ""]);
        } else if (error.response.status === 500) {
          setError("An error occurred on the server. Please try again later.");
          setPins(["", "", "", ""]);
        } else {
          setError("An unexpected error occurred. Please try again later.");
          setPins(["", "", "", ""]);
        }
      } else if (error.request) {
        setError(
          "No response received from the server. Please try again later."
        );
        setPins(["", "", "", ""]);
      } else {
        setError("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  // If clubId or doorId is not provided, show a 404 error message
  if (!clubId || !doorId) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-100">
        <Alert severity="error">Error 404: Page Not Found</Alert>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col justify-start items-center bg-gray-100 pt-10">
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      {!error && !success && (
        <div className="text-center mb-8 shadow p-10 bg-white">
          <h2 className="text-3xl font-bold mb-6">Enter your PIN</h2>
          <div className="flex flex-col items-center mb-4">
            <p className="text-xl text-gray-800 font-semibold mb-2">
              Club Name: {clubName}
            </p>
            <p className="text-xl text-gray-800 font-semibold mb-2">
              Door ID: {doorId}
            </p>
          </div>
          <div className="flex justify-center items-center mb-8">
            {pins.map((pin, index) => (
              <input
                key={index}
                type="text"
                className="w-12 h-12 text-center border border-gray-300 rounded-md mx-1 focus:outline-none focus:ring focus:ring-blue-500"
                maxLength="1"
                value={pin}
                onChange={(e) => handleChange(e, index)}
              />
            ))}
          </div>
          <button
            onClick={handleSubmit}
            className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      )}
    </div>
  );
};

export default PinEntryPage;
