import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Login from "./pages/login/Login";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";
import { lazy, Suspense } from "react";
import { useAuthContext } from "./hooks/useAuthContext";
import Loading from "./components/Loading";

import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import NotFound from "./pages/notFound/NotFound";
import PinEntryPage from "./pages/pinEntryPage/PinEntryPage";

const Club = lazy(() => import("./pages/club/club/Club"));
const ClubDataTable = lazy(() =>
  import("./pages/club/clubDataTable/ClubDataTable")
);
const ViewClub = lazy(() => import("./pages/club/clubViewPage/ViewClub"));
const Sport = lazy(() => import("./pages/sport/sport/Sport"));
const Admin = lazy(() => import("./pages/admin/admin/Admin"));

function App() {
  const { user } = useAuthContext();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route
            index
            element={user ? <Navigate to="/clubs" /> : <Navigate to="/login" />}
          />
          <Route
            path="login"
            element={!user ? <Login /> : <Navigate to="/" />}
          />
          <Route
            path="reset-password"
            element={!user ? <ForgotPassword /> : <Navigate to="/" />}
          />
          <Route
            path="reset-password/:token"
            element={!user ? <ResetPassword /> : <Navigate to="/" />}
          />

          <Route path="doors/door-qr-scan" element={<PinEntryPage />} />

          {user ? (
            <>
              <Route
                path="clubs"
                element={
                  <Suspense fallback={<Loading />}>
                    <Club />
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Loading />}>
                      <ClubDataTable />
                    </Suspense>
                  }
                />
                <Route
                  path="club-details/:rowId"
                  element={
                    <Suspense fallback={<Loading />}>
                      <ViewClub />
                    </Suspense>
                  }
                >
                  <Route path="facility" element={<ViewClub />} />
                  <Route path="court" element={<ViewClub />} />
                </Route>
              </Route>

              <Route path="sports">
                <Route
                  index
                  element={
                    <Suspense fallback={<Loading />}>
                      <Sport />
                    </Suspense>
                  }
                />
              </Route>

              <Route path="sport">
                <Route index element={<Sport />} />
              </Route>

              {user.role === "super_admin" ? (
                <Route path="admins">
                  <Route
                    index
                    element={
                      <Suspense fallback={<Loading />}>
                        <Admin />
                      </Suspense>
                    }
                  />
                </Route>
              ) : (
                ""
              )}
            </>
          ) : (
            <Route
              path="login"
              element={!user ? <Login /> : <Navigate to="/" />}
            />
          )}
        </Route>
        {/* Catch-all route for 404 page */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
